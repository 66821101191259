a{
    color: inherit;
    text-decoration: none;
    display: inline-block;
}

.footer{
    margin-top: auto;
}

.about-image{
    max-width: 200px;
    max-height: 200px;
}

.quick-links{
    margin-right: 1vw;
}

.quick-links > ul > li{
    list-style: none;
}