/* Global colours */
:root{
  --underline: #b4fee2;
  --hoverText: #ffcb3c;
  --divider: #fff421;
  --dropDownBackground: #212529;
  --dropdownText: #b3b5b6;
  --dropdownTitles: white;
  --selectText: #fcba03;
  --navBarText: white;
  --pricesText: white;
  --pricesTitles: white;
  --bgDark: #212529;
  --mobileNavigToggle: #8cb3f5;
}

@font-face{
  font-family: "Jost";
  src: local('Jost'), url(./fonts/Jost-Black.ttf) format('truetype');
}

@font-face{
  font-family: "Montserrat";
  src: local('Montserrat'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
}

/* prevents horizontal scroll bar */
html, body {
  max-width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile user touch-feedback code (Highlights text yellow for 0.5s) */
.touchedItem{
  animation: touched 0.5s;
  
}

@keyframes touched{
  0%{
    background-color: inherit;
  }
  100%{
    background-color: #fcba03;
  }
}