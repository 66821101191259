.container{
    margin-left: auto;
    margin-right: auto;
    font-family: "Montserrat";
    font-weight: 400;
}

.item{
    max-width: 600px;
    padding: 10px 0px 10px 0px;
}

.titlePrice{
    display: flex;
    max-width: 600px;
    padding: 5px;
    order: 0;
    color: var(--pricesTitles);
}

.titlePrice:after{
    border-bottom: 2px dotted var(--pricesTitles);
    margin-bottom: 1.2em;
    margin-left: 10px;
    margin-right: 10px;
    content: '';
    flex: 1;
    flex-grow: 1;
}

.treatment{
    font-size: x-large;
}

.price{
    float: right;
    order: 3;
    font-size: x-large;
}

.description{
    flex-direction: row;
    font-size: medium;
    word-wrap: break-word;
    overflow: visible;
    color: var(--pricesText)
}

.descriptionText{
    font-size: large;
}

.icon{
    margin: 5px;
    margin-right: 10px;

}

/*  */
hr{
    width: 125px;
    border-width: 2px;
    border-color: var(--divider);
}

.list-group{
    list-style-type: none;
}

/* Corners of prices page. Bandaid fix. Now animated */
#root{
    background: linear-gradient(160deg, #ffe07c, #01070c, #212335);
    background-size: 400% 400%;
    animation: Gradient 20s ease infinite;
    position: relative;

    width: 100%;
    overflow: hidden;
    padding:0;
    margin:0px;
}

@keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @media (prefers-reduced-motion) {
    #root{
        background-color: var(--bgDark);
    }
  }