.containerInner{
    color: white !important;
}

.dropdown-item{
    color: var(--dropdownText) !important;
}

.containerOuter{
    background-color: var(--dropDownBackground);
    /* padding: 100px; */
    color: var(--dropdownTitles);
    font-family: "Montserrat";
    border: 1px solid rgb(94, 94, 94);
    border-top: 0;
    border-radius: 0% 0% 7px 7px;
    opacity: 0;
}


/* dropdown animation */

@keyframes dropdownAnim{
    0%{
        transform: translateY(5%);
        opacity: 0;
    }
    100%{
        transform: translateY(0px);
        opacity: 1;
    }
}

.containerOuter{
    animation: dropdownAnim 0.25s forwards;
}

