
::selection{
    /* background-color: rgba(84,255,193,1); */
    background-color: var(--hoverText);
}

.navig{
    position: "fixed";

}

nav{
    font-family: "Jost-Black";
    background: rgb(0,0,0);
    background: radial-gradient(at 50% -350px, rgba(0,0,0,1) 20%,  rgb(122, 156, 160) 10%, rgba(0,0,0,1) 98%);
}


/* !important supposedly is a buggy fix for bootstrap ignoring color change */


@media(hover: hover) and (pointer: fine) {
  a:hover{
    color: var(--hoverText) !important;  
  }
}

.dropdown-toggle{
  color: var(--navBarText)!important;
}

.hover-underline{
    display: inline-block;
    position: relative;
    color: var(--navBarText) !important;
}
  
.hover-underline::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: var(--underline);
  /* transform-origin: bottom right; */
  transition: transform 0.25s ease-out;
}


.hover-underline:hover::after {
  transform: scaleX(1);
  /* transform-origin: bottom left; */
}


.nav-item.dropdown:hover .dropdown-menu{
  display: inline-block;
  /* margin-top: 0 prevents the dropdown from closing prematurely, since there's a small gap between the button and the dropdown */
  margin-top: 0;
  /* opacity: 100%; */
  transition: all 1s;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  
}


.dropdown-menu{
  width:  80vw;
  max-width: 900px;
  
}

#navbar-mobile-toggle{
  background-color: var(--mobileNavigToggle);
  color: white;
}

.dropdown-menu.show{
  opacity: 1;
}

.navbar-mobile{
  position: absolute;
}

.languageSwitcher{
  position: absolute;
  padding-top: 10px;
  top: 0;
  right: 0;
}

/* Haptic feedback for language buttons */
/* DUMB */
#en{
  position: absolute;
  right: 60%;
  opacity: 0;
  fill: rgb(24, 234, 9);
}

#nl{
  position: absolute;
  right: 10%;
  opacity: 0;
  fill: rgb(24, 234, 9);
}

#en.clicked{
  animation-name: clickAnimation;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
}

#nl.clicked{
  animation-name: clickAnimation;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
}



@keyframes clickAnimation {
  0%{
    background-color: black;
    opacity: 0;
    transform: translateY(0%);
  }
  25%{
    background-color: rgba(0,0,0,0.3);
    opacity: 1;
    
  }
  50%{
    background-color: rgba(0,0,0,0);
    transform: translateY(0%);
  }
  100%{
    transform: translateY(-100%);
  }
}