.tableOfContents{
    padding-top: 20vh;
}

.about-list > li{
    margin: 10px;
}

.about-selected{
    background-color: white;
    transform: translateX(1px);
}
