/* section background image */
.bg-image {
    position: relative; 
    height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.bg-image::before {    
    content: "";
    background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 1) 1%, rgba(0, 51, 51, 0.6) 86%, rgba(51, 51, 51, 0.8) 100%), 
    url(../../images/background1.jpg);

    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 1;
    
}

.title{
    color: white; 
    font-size: 6vw;
    margin: 10px 0.2vw 10px 0.2vw;
    white-space: nowrap;
    width: 100%;
    position: relative;
    
}

.a{
    animation: animtext 0.2s steps(80, end); 
}

.b{
    animation: animtext 0.2s steps(80, end); 
    animation-delay: 0.15s;
    animation-fill-mode: both;
}

.c{
    animation: animtext 0.2s steps(80, end);
    animation-delay: 0.30s;
    animation-fill-mode: both;
}

.d{
    animation: animtext 0.2s steps(80, end); 
    animation-delay: 0.45s;
    animation-fill-mode: both;
}


.textContainer{
    display: flex;
    flex-direction: row;
    max-width: auto;

    animation: moveAway 0.5s steps(180, end);
    animation-delay: 0.75s; 
    animation-fill-mode: forwards;
    
}

.descriptionHome{
    position: absolute;
    color: whitesmoke;
    font-family: "Montserrat";
    animation: descripAnim 1s linear 0.75s both;
}

/* Title animations */
@keyframes animtext {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* TEST */
@keyframes moveAway{
    0%{
        transform: translateY(0px);
    }
    100%{
        /* FAILURE: potentially might be a problem on phones */
        transform: translateY(-300px);      
        

    }
}
  

@keyframes descripAnim{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

/* section opening-times */
.openingTimes{
    max-width: 600px;
    color: white;
}

.dayTime{
    display: flex;
    max-width: 600px;
    padding: 5px;
    order: 0;
}

.dayTime:after{
    border-bottom: 2px outset white;
    margin-bottom: 0.8em;
    margin-left: 10px;
    margin-right: 10px;
    content: '';
    flex: 1;
    flex-grow: 1;
}

.day{
    font-size: large;
}

.time{
    float: right;
    order: 3;
    font-size: large;
}

/* fade in animation for opening times */

.hidden{
    opacity: 0;
    transform: translateY(100%);
    transition: all 0.5s;
}

.show{
    opacity: 1;
    transform: translateY(0%)
}
/* 
.dayTime:nth-child(2){
    transition-delay: 100ms;
}
.dayTime:nth-child(3){
    transition-delay: 200ms;
}
.dayTime:nth-child(4){
    transition-delay: 300ms;
}
.dayTime:nth-child(5){
    transition-delay: 400ms;
}
.dayTime:nth-child(6){
    transition-delay: 500ms;
}
.dayTime:nth-child(7){
    transition-delay: 600ms;
}
.dayTime:nth-child(8){
    transition-delay: 700ms;
}
.dayTime:nth-child(9){
    transition-delay: 800ms;
} */

/* disables animation for users that prefer no animation */
@media(prefers-reduced-motion){
    .hidden{
        transition: none;
    }
}

/* fade in animation for opening times */

.map-contacts{
    background: rgb(249,255,145);
    background: linear-gradient(291deg, rgba(249,255,145,1) 3%, rgba(244,245,184,0.9416141456582633) 24%, rgba(247,191,17,1) 100%);
    width: 60vw;
    position: relative;

}

.socials-container{
    width: 50px;
}

.social-item{
    width: 48px;
    height: 48px;
    background-color: white;
    border-radius: 50%;
    transition: all 1s;
    transform: translateX(0%);
    display: inline-flex;
}

.social-item:hover{
    width: 1070%;   /* Horrible, but the only solution so far */
    margin-right: auto;
    border-radius: 9%;
    transform: translateX(-83%);
    transition: all 0.4s;
}

.social-item > span{
    opacity: 0;
    transition: all 0.1s;
    overflow: none;
    display: inline;
    cursor: pointer;
}

.social-item:hover > span{
    opacity: 1;
    transition: all 1.5s;
    display: inline;
}

@media only screen and (max-width: 992px){
    .social-item:hover{
        width: 1070%;
        transform: translateX(0%);
    }

    .social-item > span {
        padding-top: 5px;
    }
}


iframe{
    min-width: 250px;
}

/* flipping phone icon horizontally */
#phone-icon{
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

.contactInfo{
    font-family: "Montserrat";
}




/*Fancy image track*/

#image-track {
    padding-left: 2vw;
    display: flex;
    gap: 4vmin;
    transform: translate(0%, 0%); 
    user-select: none; /* -- Prevent image highlighting -- */
}
  
  #image-track > .image {
    width: 45vmin;
    height: 56vmin;
    object-fit: cover;
    object-position: 100% center;
}


/* track arrow animation */
.arrow{
    position: absolute;
    top: 25%;
    left: 25%;
    animation: arrow 2s infinite;
    
}


@keyframes arrow{
    0% {
        transform: translateX(100%);

        opacity: 0;
    }
    80% {
        transform: translateX(0%);

        opacity: 1;

    }
    100%{
        opacity: 0;
    }
}